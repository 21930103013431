<template>
  <section
    ref="el"
    class="
    px-4
    py-3
    sticky
    w-full
    mt-auto
    bg-white
    shadow-xl
    bottom-[0]
    text-white
    rotate-180">
    <div class="mx-auto max-w-7xl rotate-180">
      <div class="flex items-center justify-around :justify-between rounded-full p-2 brand-gradient">
        <span class="md:ml-4 text-xs md:text-sm">{{ $t('Positions') }}: {{ (totalPositions).toLocaleString('ru') }}</span>
        <span class="md:ml-6 text-xs md:text-sm">{{ $t('Total quantity') }}: {{ (totalItems).toLocaleString('ru') }}</span>
        <span class="hidden md:block md:ml-auto text-xl">{{ (total).toLocaleString('ru') }} ₽</span>
        <NuxtLink :to="{name: 'cart'}">
          <Button
            :label="$t('Go to cart')"
            class="hidden md:block ml-6 p-button-go-to-cart"
          />
          <Button
            icon="pi pi-shopping-cart"
            :label="(total).toLocaleString('ru') + ' ₽'"
            class="md:hidden ml-1 p-button-go-to-cart"
          />
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useElementHeightToCssVariable } from '~/composables/useElementHeightToCssVariable'

const cartStore = useCartStore()
cartStore.fetch()
const total = computed(() => cartStore.total)
const totalItems = computed(() => cartStore.total_items)
const totalPositions = computed(() => cartStore.total_positions)

const { el } = useElementHeightToCssVariable('--q-cart-global-height')
</script>

<style scoped>

</style>
